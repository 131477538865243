'use client';
import {
  CHECKOUT_START_DATETIME,
  MIN_PRICES,
  PACKAGE_STORAGE,
  SELECTED_HOTEL_STORAGE,
  SOCIAL_LOGIN_REDIRECT,
} from '@config/storageKeys';
import { useCurrencyContext } from '@contexts/currency-context';
import { useBuildPackageBundle } from '@hooks/use-build-package-bundle';

import useStorage from '@hooks/useStorage';
import { IFormValues } from '@interfaces/buildPackage';
import { PropertyDetails } from '@interfaces/expedia/property';
import { useBuildPackagesStore } from '@store/build-package-store';
import { useSearchHotelStore } from '@store/search-hotel-store';
import { useIsClient } from '@uidotdev/usehooks';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import { links, v2Links } from '@utils/navigation/links';
import { Badge, Button, Check } from '@v2/ui';
import { cn } from '@v2/utils';
import { setCookie } from 'cookies-next';
import { useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';

type Props = {
  propertyDetails: PropertyDetails;
};

export function RooomSelectionBottomHeader(props: Props) {
  const storage = useStorage();
  const [currentPackageData, setCurrentPackageData] = useState<IFormValues>(
    JSON.parse(storage.getItem(PACKAGE_STORAGE) ?? '{}')
  );

  const isClient = useIsClient();
  const searchParams = useSearchParams();
  const eventId = searchParams?.get('eventId');

  const router = useRouter();
  const { propertyDetails } = props;
  const roomConfigurations = useBuildPackagesStore(
    (state) => state.roomConfigurations
  );

  const { priceRate, currency } = useCurrencyContext();
  const { isBundle, minTicketPrice } = useBuildPackageBundle();

  const roomSelections = useSearchHotelStore((state) => state.roomSelections);

  const isRoomFilled = roomConfigurations.length === roomSelections.length;

  const totalPrice = useMemo(() => {
    if (!roomSelections.length) return 0;

    // @ts-ignore
    return roomSelections.reduce((acc, value) => acc + value.price, 0);
  }, [roomSelections]);

  const nextStep = useCallback(() => {
    const hotel = propertyDetails;
    const setSelectedDataToStorage = () => {
      if (hotel) {
        const registrationTime = {
          checkIn: currentPackageData?.hotelStartDate,
          checkOut: currentPackageData?.hotelEndDate,
        };
        const data = {
          id: hotel.id,
          name: hotel.name,
          location: hotel.address,
          rating: hotel.rating,
          rooms: roomSelections.length,
          registrationTime,
          price: totalPrice,
          roomSelections,
          postalCode: hotel.address.postalCode,
          city: hotel.address.city,
          country: hotel.address.countryCode,
          phone: hotel?.phone,
          fax: hotel?.fax,
          image: hotel?.image,
          fullAddress: hotel?.fullAddress,
        };
        storage.setItem(SELECTED_HOTEL_STORAGE, JSON.stringify(data));
      }
    };

    const mp = { ticket: currentPackageData.tickets, hotel: totalPrice };
    storage.setItem(MIN_PRICES, JSON.stringify(mp));
    setSelectedDataToStorage();
    const checkoutUrl = `${links.checkout}?eventId=${eventId}`;

    if (currentPackageData.selected.tickets) {
      setCookie(CHECKOUT_START_DATETIME, new Date().getTime());
      storage.setItem(SOCIAL_LOGIN_REDIRECT, checkoutUrl);
      return router.push(`${v2Links.searchTickets}?eventId=${eventId}`);
    }

    setCookie(CHECKOUT_START_DATETIME, new Date().getTime());
    storage.setItem(SOCIAL_LOGIN_REDIRECT, checkoutUrl);
    router.push(checkoutUrl);
  }, [
    currentPackageData?.hotelEndDate,
    currentPackageData?.hotelStartDate,
    propertyDetails,
    totalPrice,
  ]);

  const { packageGuests } = useBuildPackageBundle();

  const isHotelIncluded = currentPackageData?.selected?.hotel;
  const bottomTotalPriceLabel = isBundle
    ? 'Include taxes, hotel & tickets'
    : 'Include taxes & hotel';

  if (!isClient) return null;

  const basePrice = isBundle ? totalPrice + minTicketPrice : totalPrice;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-20 w-full bg-gray-900">
      <div className="flex flex-col items-center justify-between gap-y-4 px-4 py-5 md:mx-auto md:max-w-[1400px] md:flex-row md:px-20">
        <div className="flex items-start justify-between gap-x-3 md:justify-start">
          <div>
            <h5 className="text-lg font-bold text-white">
              Estimated{' '}
              {formatCurrencyV2(
                basePrice / packageGuests,
                priceRate,
                currency,
                false,
                false
              )}{' '}
              per person
            </h5>

            <span className="text-sm font-medium text-gray-400">
              {formatCurrencyV2(basePrice, priceRate, currency, false, false)}{' '}
              in total. {bottomTotalPriceLabel}
            </span>
          </div>

          <Badge
            size="sm"
            variant="primary"
            className="flex-shrink-0 rounded-full"
          >
            {roomConfigurations.length === roomSelections.length ? (
              <>
                <Check
                  size="12"
                  pathProps={{ stroke: 'white' }}
                  className="flex-shrink-0"
                />

                <span className="ml-2.5 text-xs font-semibold text-white">
                  All selected{' '}
                </span>
              </>
            ) : (
              `${roomConfigurations.length - roomSelections.length} room(s) left`
            )}
          </Badge>
        </div>

        <Button
          onClick={nextStep}
          disabled={!isRoomFilled}
          className={cn(
            !isRoomFilled && 'bg-gray-800 text-gray-400',
            'w-full md:w-max'
          )}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}
