'use client';
import useEventLoader from '@hooks/use-event-loader';
import { LeagueEvent } from '@interfaces/APITravel';
import { useEffect, useState } from 'react';
import { ArenaView, ConfirmSelection, Sidebar } from './components';

import { PACKAGE_STORAGE } from '@config/storageKeys';
import useStorage from '@hooks/useStorage';
import { IFormValues } from '@interfaces/buildPackage';
import { useBuildPackagesStore } from '@store/build-package-store';
import { BuildPackageSteps, PageLoader } from '@v2/components';
import { cn } from '@v2/utils';
import { useTicketsStore } from 'src/stores/tickets-store';
import { useMediaQuery } from 'usehooks-ts';
import { TicketSelectionBottomHeader } from './components/TicketSelectionBottomHeader';

type PageProps = {
  event: LeagueEvent;
  eventId: string;
};

export function SearchTicketView({ event, eventId }: PageProps) {
  const { tickets, loading } = useEventLoader(eventId, true);
  const ticketGroups = tickets?.ticket_groups || [];
  const storage = useStorage();
  const [currentPackageData] = useState<IFormValues>(
    JSON.parse(storage.getItem(PACKAGE_STORAGE) ?? '{}')
  );
  const data = useBuildPackagesStore();
  const [ticketSelected, setTicketSelected, isTicketSelectionConfirmed] =
    useTicketsStore((state) => [
      state.ticketSelected,
      state.setTicketSelected,
      state.isTicketSelectionConfirmed,
    ]);

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    () => setTicketSelected(null);
  }, []);

  useEffect(() => {
    if (currentPackageData?.tickets > 0) {
      useTicketsStore.setState({ ticketsQuantity: data.tickets });
    }
  }, [data?.tickets]);

  if (loading) return <PageLoader />;
  return (
    <>
      <section className="buy-package-height pt-4 md:mx-auto md:h-[720px] md:w-[1400px] md:px-20 md:pt-12">
        <BuildPackageSteps active="Tickets" />

        <div className="border-gary-50 mt-4 flex h-auto flex-col-reverse rounded-lg border md:h-[720px] md:flex-row md:items-start">
          <>
            {' '}
            <aside
              className={cn(
                'h-full w-full md:w-[40%]',
                ticketSelected && 'border-gray-200 md:border-r'
              )}
            >
              {ticketSelected ? (
                <ConfirmSelection event={event} ticketGroups={ticketGroups} />
              ) : (
                <Sidebar
                  ticketsList={ticketGroups}
                  loading={loading}
                  event={event}
                />
              )}
            </aside>
            {
              <div className="h-full w-full bg-white md:w-[60%]">
                <ArenaView event={event} ticketGroups={ticketGroups} />
              </div>
            }
          </>
        </div>
      </section>
      {((isTicketSelectionConfirmed && isMobile) || !isMobile) && (
        <TicketSelectionBottomHeader
          event={event}
          ticketGroups={ticketGroups}
        />
      )}
    </>
  );
}
