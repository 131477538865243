'use client';
import { Button, ToggleGroup, ToggleGroupItem, Typography } from '@v2/ui';

import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';
import { v2Links } from '@utils/navigation/links';
import Image from 'next/image';
import { useRouter, useSearchParams } from 'next/navigation';
import React from 'react';

export type PackageType = 'packages' | 'tickets';
export function BuyPackageContent() {
  const [selected, setSelected] = React.useState<PackageType | null>(null);

  const searchParams = useSearchParams();

  const eventId = searchParams?.get('eventId');
  const eventLink = searchParams?.get('eventLink');

  const router = useRouter();

  const handleClick = () => {
    const url = `${v2Links.buildPackage}?packageType=${selected}&eventId=${eventId}&eventLink=${eventLink}`;
    router.push(url);
  };

  const isTablet = useBetterMediaQuery('(min-width: 768px)');

  return (
    <div className="flex w-full flex-col gap-y-12">
      <Typography
        size={isTablet ? 'dmd' : 'dxs'}
        className="text-center font-bold text-gray-800"
      >
        I’m interested in...{' '}
      </Typography>

      <ToggleGroup
        type="single"
        variant="outline"
        className="gap-3"
        value={selected || ''}
        onValueChange={(val: PackageType) => setSelected(val)}
      >
        <ToggleGroupItem
          value="packages"
          className="flex h-[210px] w-[274px] flex-col items-center gap-y-5 px-0"
        >
          <Image
            src={'/icons/luggage.svg'}
            alt="travel packages"
            width={80}
            height={80}
          />
          <Typography
            size={isTablet ? 'txl' : 'tmd'}
            className="text-center font-bold text-gray-800"
          >
            Travel packages
          </Typography>
        </ToggleGroupItem>
        <ToggleGroupItem
          value="tickets"
          className="flex h-[210px] w-[274px] flex-col items-center gap-y-5 px-0"
        >
          <Image
            src={'/icons/plane-tickets.svg'}
            alt="Only tickets"
            width={80}
            height={80}
          />
          <Typography
            size={isTablet ? 'txl' : 'tmd'}
            className="text-center font-bold text-gray-800"
          >
            Only tickets
          </Typography>
        </ToggleGroupItem>
      </ToggleGroup>

      <Button
        className="w-full"
        disabled={!selected?.length}
        onClick={handleClick}
      >
        Continue
      </Button>
    </div>
  );
}
