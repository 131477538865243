import { useCurrencyContext } from '@contexts/currency-context';
import { useBuildPackageBundle } from '@hooks/use-build-package-bundle';
import useMinimumPrice from '@hooks/useMinimumPrice';
import { TicketGroup } from '@interfaces/ticketevolution/tikets';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import { Skeleton, Typography } from '@v2/ui';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { useTicketsStore } from 'src/stores/tickets-store';

type Props = {
  list: TicketGroup[];
  loading: boolean;
};

const findUniqueSectionNames = (arr: TicketGroup[]) => {
  const sectionsArray: string[] = [];
  arr.forEach(({ section }) => sectionsArray.push(section));
  return [...new Set(sectionsArray)];
};

export function TicketList({ list, loading }: Props) {
  const isOnlyElectronic = useTicketsStore((state) => state.isOnlyElectronic);
  const selectedTicketGroups = useTicketsStore((state) => state.selectedGroups);
  const ticketsQuantity = useTicketsStore((state) => state.ticketsQuantity);
  const [min, max] = useTicketsStore((state) => state.priceRange);

  const { ticketPrice: minTicketPrice, priceLoading } = useMinimumPrice(
    [],
    list,
    ticketsQuantity
  );

  const itemsFiltered = useMemo(() => {
    if (!list.length) return [];
    const selectedTicketsByGroups = list.filter(({ tevo_section_name }) =>
      selectedTicketGroups.includes(tevo_section_name)
    );

    const uniqueSections = findUniqueSectionNames(selectedTicketsByGroups);
    return list.filter(
      ({ retail_price, section, quantity, eticket: Eticket, splits }) => {
        return (
          retail_price >= min &&
          retail_price * ticketsQuantity <= max &&
          quantity >= ticketsQuantity &&
          (uniqueSections.length === 0 || uniqueSections.includes(section)) &&
          ((isOnlyElectronic && Eticket) || !isOnlyElectronic) &&
          splits.includes(ticketsQuantity)
        );
      }
    );
  }, [isOnlyElectronic, selectedTicketGroups, min, max, ticketsQuantity, list]);

  return (
    <div className="max-h-[480px] space-y-3 overflow-y-auto p-4 pt-0 md:max-h-[440px]">
      {loading &&
        [0, 1, 2, 3, 4, 5].map((item) => (
          <Skeleton key={item} className="h-[94px] w-full" />
        ))}

      {!loading && !itemsFiltered.length && (
        <Typography
          size="tmd"
          className="text-center font-medium text-gray-500"
        >
          Sorry, we couldn&apos;t find any seats that matched your search.
        </Typography>
      )}
      {!loading &&
        itemsFiltered?.length > 0 &&
        itemsFiltered.map((item, index) => (
          <TicketItem key={index} {...item} minTicketPrice={minTicketPrice} />
        ))}
    </div>
  );
}

type TicketItemProps = TicketGroup & {
  minTicketPrice: number;
};

function TicketItem(props: TicketItemProps) {
  const {
    signature,
    section,
    retail_price,
    available_quantity,
    row,
    minTicketPrice,
  } = props;
  const setTicketSelected = useTicketsStore((state) => state.setTicketSelected);
  const ticketsQuantity = useTicketsStore((state) => state.ticketsQuantity);
  const { priceRate, currency } = useCurrencyContext();

  const { isBundle } = useBuildPackageBundle();

  const packagePrice =
    (retail_price * ticketsQuantity - (minTicketPrice ?? 0)) / ticketsQuantity;

  const finalPrice = isBundle ? packagePrice.toFixed(2) : retail_price;
  return (
    <div className="rounded-xl border border-gray-200 bg-white p-5">
      <button
        className="flex w-full items-center justify-between"
        onClick={() => setTicketSelected(props)}
      >
        <div className="flex flex-col gap-y-1 text-start">
          <Typography
            size="txl"
            className="max-w-[15ch] truncate font-bold md:max-w-[20ch]"
          >
            Section {section}, Row {row}
          </Typography>
          <Typography size="tsm" className="font-medium text-gray-500">
            {available_quantity} {pluralize('ticket', available_quantity)}{' '}
            {signature && ` • ${signature}`}
          </Typography>
        </div>
        <div className="flex flex-col gap-y-1">
          <Typography size="txl" className="font-bold">
            + {formatCurrencyV2(finalPrice, priceRate, currency, false, false)}
          </Typography>
          <Typography size="tsm" className="font-medium text-gray-500">
            Per person
          </Typography>
        </div>
      </button>
    </div>
  );
}
