'use client';
import { LeagueEvent } from '@interfaces/APITravel';
import { TicketGroup } from '@interfaces/ticketevolution/tikets';
import {
  Button,
  Checkbox,
  Label,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Typography,
} from '@v2/ui';
import { Slider, SliderRange, SliderThumb, SliderTrack } from '@v2/ui/Slider';
import { useEffect, useMemo, useState } from 'react';
import { useTicketsStore } from 'src/stores/tickets-store';
import { TicketList } from './TicketList';

type Props = {
  event: LeagueEvent;
  ticketsList: TicketGroup[];
  loading: boolean;
};

export function Sidebar({ ticketsList, loading, event }: Props) {
  const [tab, setTab] = useState('lowestPrice');

  const [ticketsQuantity, setTicketsQuantity] = useTicketsStore((state) => [
    state.ticketsQuantity,
    state.setTickets,
  ]);

  const [priceRange, setPriceRange] = useTicketsStore((state) => [
    state.priceRange,
    state.setPriceRange,
  ]);

  const toggleBoolean = useTicketsStore((state) => state.toggleBoolean);
  const isOnlyElectronic = useTicketsStore((state) => state.isOnlyElectronic);

  const ticketRangePrice = useMemo(() => {
    const temp = { min: 0, max: 100 };
    if (ticketsList.length) {
      const priceArray: number[] = [];
      ticketsList.forEach(({ retail_price }) => {
        if (typeof retail_price === 'number') {
          priceArray.push(retail_price);
        }
      });
      const minRange = Math.floor(Math.min(...priceArray));
      const maxRange = Math.ceil(Math.max(...priceArray));

      temp.min = minRange;
      temp.max = maxRange;
    }
    return temp;
  }, [ticketsList]);

  useEffect(() => {
    setPriceRange([ticketRangePrice.min, ticketRangePrice.max]);
  }, [ticketRangePrice.max, ticketRangePrice.min]);
  return (
    <div>
      <div className="bg-white">
        <div className="space-y-5 p-4 pb-0">
          <div className="flex items-center justify-between">
            <Typography size="tmd" className="font-semibold">
              How many tickets?
            </Typography>
            <div className="flex items-center justify-start gap-x-2">
              <Button
                size="sm"
                variant="secondary"
                onClick={() => setTicketsQuantity('decrement')}
                className="h-[36px] w-[36px]"
                type="button"
              >
                -
              </Button>
              <Typography
                size="tmd"
                className="min-w-[37px] text-center font-medium"
              >
                {ticketsQuantity}
              </Typography>
              <Button
                type="button"
                size="sm"
                variant="secondary"
                onClick={() => setTicketsQuantity('increment')}
                className="h-[36px] w-[36px]"
              >
                +
              </Button>
            </div>
          </div>
          <div>
            <Typography
              size="tmd"
              className="mb-1.5 flex items-center justify-between font-medium"
            >
              <span>${ticketRangePrice.min}</span>
              <span>${ticketRangePrice.max}</span>
            </Typography>
            <Slider
              min={ticketRangePrice.min}
              max={ticketRangePrice.max}
              minStepsBetweenThumbs={10}
              value={priceRange}
              onValueChange={(val) => setPriceRange(val)}
            >
              <SliderTrack>
                <SliderRange />
              </SliderTrack>
              <SliderThumb />
              <SliderThumb />
            </Slider>
          </div>
        </div>

        <Tabs
          value={tab}
          onValueChange={(type: string) => setTab(type)}
          className="bg-white pt-5"
        >
          <TabsList variant="outline">
            <TabsTrigger
              variant="outline"
              value="lowestPrice"
              className="w-full"
            >
              Lowest price
            </TabsTrigger>
            <TabsTrigger variant="outline" value="bestSeats" className="w-full">
              Best seats
            </TabsTrigger>
          </TabsList>
          <div className="flex items-center gap-x-3 bg-gray-50 px-5 py-4">
            <Checkbox
              className="rounded-md bg-white"
              size="sm"
              checked={isOnlyElectronic}
              onCheckedChange={() => toggleBoolean('isOnlyElectronic')}
            />
            <Label className="font-semibold text-gray-800">
              Electronic tickets only
            </Label>
          </div>
          <TabsContent value="lowestPrice" className="mt-0 bg-gray-50">
            <TicketList
              list={[...ticketsList].sort(
                (a, b) => a.retail_price - b.retail_price
              )}
              loading={loading}
            />
          </TabsContent>
          <TabsContent value="bestSeats" className="mt-0 bg-gray-50">
            <TicketList
              list={[...ticketsList].sort(
                (a, b) => b.retail_price - a.retail_price
              )}
              loading={loading}
            />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
