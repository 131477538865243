'use client';
import routes from '@config/routes';
import { useWindowScroll } from '@uidotdev/usehooks';
import { Divider, Skeleton, Typography, typographyVariants } from '@v2/ui';
import { cn } from '@v2/utils';
import { debounce } from 'lodash';
import { Suspense } from 'react';

import { useSearchHotelStore } from '@store/search-hotel-store';

import { mutate } from 'swr';
import {
  DistanceToVenueField,
  HotelFeatureField,
  PriceTravelerRangeField,
  QueryField,
  RatingField,
} from './Filters';
import { MapView } from './MapView';

export function SidebarHotelFilters() {
  const hotelFilters = useSearchHotelStore((state) => state.hotelFilters);
  const setHotelFilters = useSearchHotelStore((state) => state.setHotelFilters);
  const setHotelRange = useSearchHotelStore((state) => state.setHotelRange);

  const setHotelFilterDebounced = debounce((val) => {
    setHotelRange(val);
    setTimeout(() => {
      mutate(routes.propertySearch);
      console.log('running');
    }, 500);
  }, 500);
  const setQueryDebounced = debounce((val) => {
    setHotelFilters(val);
  }, 500);

  const [_, scrollTo] = useWindowScroll();

  return (
    <div className="w-full space-y-8">
      <div className="w-full rounded-lg border border-gray-300">
        <div className="min-h-[120px] w-full rounded-tl-lg rounded-tr-lg">
          <Suspense fallback={<Skeleton className="h-full w-full" />}>
            <MapView />
          </Suspense>
        </div>

        <button
          onClick={() => {
            // @ts-ignore
            scrollTo(0, 0);
            useSearchHotelStore.setState({ isMapFullScreen: true });
          }}
          className={cn(
            'w-full cursor-pointer rounded-b-lg bg-white p-[10px] text-center',
            typographyVariants({ size: 'tsm', class: 'font-semibold' })
          )}
        >
          Map view
        </button>
      </div>

      <Divider className="bg-gray-300" />

      <QueryField
        value={hotelFilters.query}
        onValueChange={(query) => setQueryDebounced({ query })}
      />

      <Divider className="bg-gray-300" />

      <div>
        <Typography size="txl" className="mb-5 font-bold">
          Filter by
        </Typography>

        <div className="space-y-8">
          <RatingField
            value={hotelFilters.rating}
            onValueChange={(val) => setHotelFilters({ rating: val })}
          />

          <HotelFeatureField
            value={hotelFilters.featureHotel}
            onValueChange={(val) => setHotelFilters({ featureHotel: val })}
          />

          <PriceTravelerRangeField
            value={hotelFilters.pricePerTraveler}
            onValueChange={(val) => setHotelRange({ pricePerTraveler: val })}
          />

          <DistanceToVenueField
            value={hotelFilters.distance}
            onValueChange={(val) => setHotelFilterDebounced({ distance: val })}
          />
        </div>
      </div>
    </div>
  );
}
