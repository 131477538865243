import { useCurrencyContext } from '@contexts/currency-context';
import { useBuildPackageBundle } from '@hooks/use-build-package-bundle';
import { TicketGroup } from '@hooks/use-tevomaps';
import useMinimumPrice from '@hooks/useMinimumPrice';
import { LeagueEvent } from '@interfaces/APITravel';
import { OrderTicketTaxQuotaRequest } from '@interfaces/ticketevolution/tikets';
import { getTicketsTaxQuotes } from '@pages/api/te/tax';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import { ArrowLeft, Button, Close, Divider, Phone02, Typography } from '@v2/ui';
import { cn } from '@v2/utils';
import { useState } from 'react';
import { useTicketsStore } from 'src/stores/tickets-store';
import { useMediaQuery } from 'usehooks-ts';

type Props = {
  event: LeagueEvent;
  ticketGroups: TicketGroup[];
};

export function ConfirmSelection(props: Props) {
  const { ticketGroups } = props;

  const { priceRate, currency } = useCurrencyContext();

  const [ticketSelected, setTicketSelected] = useTicketsStore((state) => [
    state.ticketSelected,
    state.setTicketSelected,
  ]);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const ticketsQuantity = useTicketsStore((state) => state.ticketsQuantity);

  const { isBundle } = useBuildPackageBundle();
  const { ticketPrice: minTicketPrice } = useMinimumPrice(
    [],
    ticketGroups,
    ticketsQuantity
  );
  const [loading, setLoading] = useState(false);

  const [isTicketSelectionConfirmed, setTicketSelectionConfirmed] =
    useTicketsStore((state) => [
      state.isTicketSelectionConfirmed,
      state.setTicketSelectionConfirmed,
    ]);

  if (!ticketSelected) return null;
  const { section, retail_price, row } = ticketSelected!;

  const handleOnCancelSelection = () => {
    setTicketSelectionConfirmed({
      isTicketSelectionConfirmed: false,
      taxQuota: {
        tax: 0,
        tax_signature: null,
      },
    });
    setTicketSelected(null);
  };

  const handleConfirmSelection = async () => {
    setLoading(true);
    const { id: ticketGroupId } = ticketSelected;
    const taxQuotaRequest = {
      ticketGroupId,
      quantity: ticketsQuantity,
    } as OrderTicketTaxQuotaRequest;

    const taxQuota = await getTicketsTaxQuotes(taxQuotaRequest);

    const tax =
      Number(taxQuota?.wholesale?.tax || 0) +
      Number(taxQuota?.wholesale?.additional_expense || 0) +
      Number(taxQuota?.wholesale?.service_fee || 0) +
      Number(taxQuota?.wholesale?.shipping || 0);

    setLoading(false);
    setTicketSelectionConfirmed({
      isTicketSelectionConfirmed: true,
      taxQuota: {
        tax,
        tax_signature: taxQuota?.tax_signature,
      },
    });
  };

  const packagePrice =
    (retail_price * ticketsQuantity - (minTicketPrice ?? 0)) / ticketsQuantity;

  const finalPrice = isBundle ? packagePrice : retail_price;

  return (
    <div
      className={cn(
        'h-full w-full bg-white',
        isMobile && 'absolute top-0 z-30'
      )}
    >
      <div className="flex w-full flex-row-reverse items-center justify-end border-b border-gray-200 p-4 md:flex-row md:justify-between">
        <Typography size="tmd" className="font-semibold">
          Confirm selection
        </Typography>
        <Button
          variant="ghost"
          onClick={handleOnCancelSelection}
          className="p-0"
        >
          {isMobile ? (
            <ArrowLeft pathProps={{ stroke: '#1D2939' }} />
          ) : (
            <Close size="20" />
          )}
        </Button>
      </div>
      <div className="space-y-6 p-4">
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-y-1 text-start">
            <Typography
              size="txl"
              className="max-w-[15ch] truncate font-bold md:max-w-[20ch]"
            >
              Section {section}, Row {row}
            </Typography>
            <Typography size="tsm" className="font-medium text-gray-500">
              {ticketsQuantity} tickets
            </Typography>
          </div>
          <div className="flex flex-col gap-y-1 text-start">
            <Typography size="txl" className="max-w-[20ch] truncate font-bold">
              {isBundle && '+ '}
              {formatCurrencyV2(
                finalPrice,
                priceRate,
                currency,
                false,
                false
              )}{' '}
              x {ticketsQuantity}
            </Typography>
            <Typography size="tsm" className="font-medium text-gray-500">
              {isBundle && '+ '}
              {formatCurrencyV2(
                finalPrice * ticketsQuantity,
                priceRate,
                currency,
                false,
                false
              )}{' '}
              in total
            </Typography>
          </div>
        </div>
        <Divider />

        <div className="flex items-start gap-x-3">
          <Phone02 size="20" />
          <Typography size="tsm" className="font-medium text-gray-500">
            Mobile ticket: Scan your tickets from your mobile phone for this
            event. Do not print these tickets.
          </Typography>
        </div>
        <Button
          disabled={loading || isTicketSelectionConfirmed}
          className="w-full"
          onClick={handleConfirmSelection}
          isLoading={loading}
        >
          {isTicketSelectionConfirmed ? 'Selected' : 'Select'}
        </Button>
      </div>
    </div>
  );
}
